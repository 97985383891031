<template>
  <NuxtLayout name="base">
    <div class="flex flex-col min-h-dvh">
      <AppHeader sticky v-bind="$attrs" />
      <div class="flex-grow min-w-0">
        <slot />
      </div>
      <AppFooter />
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { geolocationToLocation } from '~/lib/search'
import { AppProviderKeys } from '~/providers'

import type { IGooglePlace } from '~/types/common/google-place'
import type { IPSearchParameters } from '~/types/provider/search-parameters'
import type { ISearchParameters } from '~/types/search/search'

const geolocation = useGeolocation()

const searchParameters: ISearchParameters = reactive({})

provide<IPSearchParameters>(AppProviderKeys.SearchParameters, {
  searchParameters,
  updateLocation: (value?: IGooglePlace): void => {
    searchParameters.location = value
  },
  updateFilter: () => {
    throw new Error('not supported')
  },
  updateFilters: () => {
    throw new Error('not supported')
  },
  updateSortOrder: () => {
    throw new Error('not supported')
  }
})

searchParameters.location = geolocationToLocation(geolocation)
</script>
